import React from 'react';
import i18n from "../../../i18n";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CoverImage from '../../components/CoverImage';
import signUpMobile from '../../../assets/signupMobile.png';
import signUpDesktop from '../../../assets/signUpDesktop.png';
import NavBarMenu from '../../components/NavBarMenu';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from "@contentful/rich-text-types";
import generateMeta from '../../utils/meta';

const CGVPage = ({ data, pageContext, t }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];
  // i18n used for translation
  i18n(pageContext.locale);

  let CGVContent = data?.allContentfulPageDesCgv?.edges[0]?.node?.text;

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="mt-2">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <div className="pb-6">{children}</div>,
    },
  }

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang={pageContext.locale} />
        <title>Conditions Générales de Vente | Zawema</title>
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <>
        <CoverImage
          imageDesktop={signUpDesktop}
          imageMobile={signUpMobile}
          title="CGV"
          altDesktop="palm trees"
          altMobile="palm trees"
        />
      </>
      {CGVContent ?
        <div className="max-w-screen-xl mx-auto px-2 sm:px-10 mt-5 mb-20">
          {renderRichText(CGVContent, options)}
        </div>
        : null
      }
    </Layout>
  );
};

export default CGVPage;

export const query = graphql`
    query( $locale: String! ) {
        menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
              node {
                slug
                categoryName
              }
            }
          }
        menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                } 
              }
            }
          }
        allContentfulPageDesCgv(filter: {node_locale: {eq: $locale}}) {
            edges {
              node {
                id
                text {
                  raw
                }
              }
            }
          }
        markdownRemark(fileAbsolutePath: {regex: "/cgvlegal.md/"}) {
          html
          frontmatter {
              title
              description
              ogtitle
              ogdescription
          }
        }
    }
`